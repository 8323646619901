import React, { useEffect, useState } from 'react';
import { postData } from "../../modules/api";

const TelegramUserData = () => {
	const [userData, setUserData] = useState(null);
	const [actionList, setActionList] = useState([]);
	
	const addActions = (action) => {
		// Формат даты: часы:минуты:секунды:миллисекунды
		const date = new Date().toLocaleTimeString('ru-RU', { hour: '2-digit', minute: '2-digit', second: '2-digit', fractionalSecondDigits: 3 });
		setActionList((prev) => [...prev, { action, date }]);
	}
	
	useEffect(() => {
		addActions('Ожидание данных Telegram...');
		const checkTelegram = setInterval(() => {
			if (window.Telegram) {
				clearInterval(checkTelegram);
				addActions('Telegram API загружен');
				window.Telegram.WebApp.ready();
				addActions('Telegram WebApp.ready');
				
				const initData = window.Telegram.WebApp.initData;
				addActions('Telegram WebApp.initData получен');
				const request = { initData, date: Date.now() };
				if (localStorage?.tlegram_user_initData) request.initData = localStorage.tlegram_user_initData;
				addActions('Отправка данных на сервер');
				postData('/user/auth', request)
					.then(r => {
						setUserData(r);
						addActions('Данные получены');
					})
					.catch((error) => {
						console.error('auth error', error);
						addActions('Ошибка авторизации');
					});
			}
		}, 100); // Проверять каждые 100 мс
		
		return () => clearInterval(checkTelegram); // Очистка интервала при размонтировании компонента
	}, []);
	
	return (
		<div>
			<ul>
				{actionList.map((item, index) => (
					<li key={index}>{item.date.toString()} - {item.action}</li>
				))}
			</ul>
			{userData
				?
				<div>
					<h3>Добро пожаловать {userData?.username}!</h3>
					<p>Ваш ID: {userData?.id}</p>
				</div>
				:
				<div>
					<h3>Загрузка данных....</h3>
					{localStorage?.tlegram_user_initData && <div>DEV MODE ON</div>}
				</div>
			}
		</div>
	);
};

export default TelegramUserData;
