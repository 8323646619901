import React from 'react';

function App() {
	const handleLogin = () => {
		window.location.href = 'https://api.lafian.ru/steam-auth/auth/steam';
	};
	
	return (
		<div className="App">
			<button onClick={handleLogin}>Авторизоваться через Steam</button>
		</div>
	);
}

export default App;