import './App.css';
import TelegramUserData from "./components/TelegramUserData/TelegramUserData";
import SteamConnector from "./components/SteamConnector/SteamConnector";

function App() {
  return (
    <div className="App">
      <h6>Telegram Web Apps No Cache {Date.now()}</h6>
      <div>
        <SteamConnector />
        <TelegramUserData />
      </div>
    </div>
  );
}

export default App;
