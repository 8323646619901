// src/api.js
import axios from 'axios';

// Создайте экземпляр axios с базовым URL вашего API
const api = axios.create({
	baseURL: 'https://api.lafian.ru', // Замените на URL вашего API
	// baseURL: 'http://localhost:3000', // Замените на URL вашего API
	headers: {
		'Content-Type': 'application/json',
	},
});

// Функция для отправки GET-запроса
export const fetchData = async (endpoint) => {
	try {
		const response = await api.get(endpoint);
		return response.data;
	} catch (error) {
		console.error('Ошибка при выполнении GET-запроса', error);
		throw error;
	}
};

// Функция для отправки POST-запроса
export const postData = async (endpoint, data) => {
	try {
		const response = await api.post(endpoint, data);
		return response.data;
	} catch (error) {
		console.error('Ошибка при выполнении POST-запроса', error);
		throw error;
	}
};

// Функция для отправки PUT-запроса
export const putData = async (endpoint, data) => {
	try {
		const response = await api.put(endpoint, data);
		return response.data;
	} catch (error) {
		console.error('Ошибка при выполнении PUT-запроса', error);
		throw error;
	}
};

// Функция для отправки DELETE-запроса
export const deleteData = async (endpoint) => {
	try {
		const response = await api.delete(endpoint);
		return response.data;
	} catch (error) {
		console.error('Ошибка при выполнении DELETE-запроса', error);
		throw error;
	}
};
